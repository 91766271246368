import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function GurudevJourney() {
  return (
    <Layout>
      <SEO
        title="Gurudev Swami Chinmayananda | Chinmaya Vidyalaya"
        description="His Holiness Swami Chinmayananda, world-renowned Vedanta teacher, inspired band of devotees to form 'Chinmaya Mission' on August 8, 1953, guided by His vision."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="gurudevjourney">
        <h1 className="heading">Gurudev’s Journey</h1>
        {/* <div className="gurudevjourney__heading2">Principal's Message</div> */}

        <img
          className="gurudevjourney__img"
          src="https://chinmayavvdelhi.ac.in/images/Blackele/chinmaya_cutout.png"
        ></img>

        <p className="gurudevjourney__text">
          On 8 May 1916, a child was born as so many other children were born.
          Born to ordinary parents – a lawyer father, a homemaker mother, Balan
          went to school like all kids do; he would later acquire a degree in
          English Literature, plunge into the Freedom Movement of India against
          British rule, be imprisoned, fall frightfully ill, be thrown out of
          jail for that, be rescued by a strange lady, start writing for a
          newspaper, make fiery speeches and plan even more fiery exposes and
          then all too suddenly abandon all that, for none of them explained to
          him the purpose of his birth. Clearly, Swami Chinmayananda was an
          unusual young man.
          <br />
          <br />
          Swami Chinmayananda conducted his first Jnana Yajna (a series of
          spiritual discourses) in December 1951, at a small temple in Pune,
          Maharashtra. Jnana Yajna, a term he coined from Lord Krishna's
          teachings in the Shrimad Bhagavad Gita, refers to the student who
          through scriptural studies performs the ritual of worship (yajna) at
          the altar of wisdom (jnana).
          <br />
          <br />
          Swami Chinmayananda is known for teaching Bhagavad Gita, the
          Upanishads, and other ancient Hindu scriptures. From 1951 onward, he
          spearheaded a global Hindu spiritual and cultural renaissance that
          popularised the religion's esoteric scriptural texts, teaching them in
          English all across India and abroad.. An inspired band of devotees
          thus formed 'Chinmaya Mission' on August 8, 1953.
        </p>
        <center>
          <Link className="btn-dark" to="/chinmaya-mission-trust/">
            Central Chinmaya Mission Trust
          </Link>
        </center>
      </div>
    </Layout>
  );
}
